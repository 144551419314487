//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-488:_3680,_3092,_3242,_4312,_8890,_5316,_9720,_4228;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-488')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-488', "_3680,_3092,_3242,_4312,_8890,_5316,_9720,_4228");
        }
      }catch (ex) {
        console.error(ex);
      }