
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatI2(i, iIndex) {
                    return _createElement('span', {
                        'key': i,
                        'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                    });
                }
                function onClick3() {
                    {
                        window.Convermax.handleProductQtySelector(this.id, false);
                    }
                }
                function onClick4() {
                    {
                        window.Convermax.handleProductQtySelector(this.id, true);
                    }
                }
                return _createElement('li', { 'className': 'grid-item' + (this.FitmentType === 'Wheel' ? ' wheel-item' : '') }, _createElement('product-card', { 'class': 'card card-product card-product-no-rating color-background-2' }, this.on_sale ? _createElement('div', {
                    'className': 'card-badge top right',
                    'key': '193'
                }, _createElement('span', { 'className': 'badge badge-sale' }, 'Sale')) : null, _createElement('figure', { 'className': 'card-media' }, _createElement('a', {
                    'href': this.url,
                    'className': 'js-product-link'
                }, _createElement('div', { 'className': 'aspect-ratio' }, _createElement('img', {
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.custom_wheel_model ?? this.custom_tire_model ?? this.title),
                    'onError': this.onImageError,
                    'loading': 'lazy',
                    'className': 'card-media-image'
                }))), _createElement('div', { 'className': 'cm_view-on-vehicle-btn-container' }, this.wheel_mpn ? _createElement('button', {
                    'className': 'cm_view-on-vehicle-btn',
                    'onClick': () => window.Convermax.openVehicleWheelDialog(this.wheel_mpn),
                    'key': '749'
                }, _createElement('i', { 'className': 'fa-eye fa-solid' }), ' View On Vehicle\n        ') : null), _createElement('div', { 'className': 'card-info-product-meta-container mobile' }, this.FitmentType === 'Tire' ? _createElement('div', {
                    'className': 'card-info-product-meta',
                    'key': '1043'
                }, this.convermax_tire_size ? _createElement('div', {
                    'className': 'card-info-product-meta-text',
                    'key': '1136'
                }, '\n            Size: ', _createElement('strong', {}, this.convermax_tire_size)) : null, this.custom_utqg ? _createElement('div', {
                    'className': 'card-info-product-meta-text',
                    'key': '1300'
                }, '\n            UTQG: ', _createElement('strong', {}, this.custom_utqg)) : null, this.custom_tire_mileage_warranty ? _createElement('div', {
                    'className': 'card-info-product-meta-text',
                    'key': '1448'
                }, '\n            Warranty: ', _createElement('strong', {}, this.custom_tire_mileage_warranty)) : null) : null, this.FitmentType === 'Wheel' ? _createElement('div', {
                    'className': 'card-info-product-meta',
                    'key': '1647'
                }, this.convermax_wheel_size ? _createElement('div', {
                    'className': 'card-info-product-meta-text',
                    'key': '1741'
                }, '\n            Size: ', _createElement('strong', {}, this.convermax_wheel_size)) : null, this.convermax_wheel_offset ? _createElement('div', {
                    'className': 'card-info-product-meta-text',
                    'key': '1907'
                }, '\n            Offset: ', _createElement('strong', {}, this.convermax_wheel_offset)) : null) : null)), _createElement('div', { 'className': 'card-info-content' }, _createElement('div', { 'className': 'card-info-wrap' }, _createElement('div', { 'className': 'card-info' }, _createElement('span', { 'className': 'card-eyebrow' }, _createElement('a', Object.assign({}, {
                    'href': this.url,
                    'title': this.removeHTML(this.vendor)
                }, { dangerouslySetInnerHTML: { __html: this.vendor } }))), _createElement('div', { 'className': 'card-heading typography-body' }, _createElement('a', mergeProps({
                    'href': this.url,
                    'className': 'js-product-link'
                }, { dangerouslySetInnerHTML: { __html: this.custom_wheel_model ?? this.custom_tire_model ?? this.title } }))), _createElement('div', { 'className': 'card-wheel-finish' }, this.custom_finish ? _createElement('span', { 'key': '2701' }, this.custom_finish) : null), _createElement('div', { 'className': 'card-info-product-meta-container desktop' }, this.FitmentType === 'Tire' ? _createElement('div', {
                    'className': 'card-info-product-meta',
                    'key': '2856'
                }, this.convermax_tire_size ? _createElement('div', {
                    'className': 'card-info-product-meta-text',
                    'key': '2953'
                }, '\n                Size: ', _createElement('strong', {}, this.convermax_tire_size)) : null, this.custom_utqg ? _createElement('div', {
                    'className': 'card-info-product-meta-text',
                    'key': '3129'
                }, '\n                UTQG: ', _createElement('strong', {}, this.custom_utqg)) : null, this.custom_tire_mileage_warranty ? _createElement('div', {
                    'className': 'card-info-product-meta-text',
                    'key': '3289'
                }, '\n                Warranty: ', _createElement('strong', {}, this.custom_tire_mileage_warranty)) : null) : null, this.FitmentType === 'Wheel' ? _createElement('div', {
                    'className': 'card-info-product-meta',
                    'key': '3504'
                }, this.convermax_wheel_size ? _createElement('div', {
                    'className': 'card-info-product-meta-text',
                    'key': '3602'
                }, '\n                Size: ', _createElement('strong', {}, this.convermax_wheel_size)) : null, this.convermax_wheel_offset ? _createElement('div', {
                    'className': 'card-info-product-meta-text',
                    'key': '3780'
                }, '\n                Offset: ', _createElement('strong', {}, this.convermax_wheel_offset)) : null) : null), !this.out_of_stock ? [
                    this.inventory_quantity >= 8 ? _createElement('div', {
                        'className': 'badge-stock badge-stock-in',
                        'key': '39961'
                    }, _createElement('span', { 'className': 'badge-stock-icon' }), ' In stock\n            ') : null,
                    this.inventory_quantity < 8 ? _createElement('div', {
                        'className': 'badge-stock badge-stock-warning',
                        'key': '39963'
                    }, _createElement('span', { 'className': 'badge-stock-icon' }), ' Only ', this.inventory_quantity, ' left\n            ') : null
                ] : null, this.out_of_stock ? _createElement('div', {
                    'className': 'badge-stock badge-stock-out',
                    'key': '4437'
                }, _createElement('span', { 'className': 'badge-stock-icon' }), ' Out of stock\n          ') : null))), _createElement('div', { 'className': 'card-footer' }, [this.review_count ? _createElement.apply(this, [
                        'div',
                        {
                            'className': 'cm_review-stars',
                            'key': '46550'
                        },
                        _map([
                            0,
                            1,
                            2,
                            3,
                            4
                        ], repeatI2.bind(this)),
                        _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                    ]) : null], this.FitmentType === 'Wheel' || this.FitmentType === 'Tire' ? _createElement('div', {
                    'className': 'price-set-of-four',
                    'key': '5058'
                }, this.on_sale ? _createElement('div', {
                    'className': 'compare-at-price',
                    'key': '5186'
                }, _createElement('span', { 'className': 'money' }, this.formatPrice(this.compare_at_price * 4)), _createElement('div', { 'className': 'set-label' }, 'SET OF 4')) : null, _createElement('div', { 'className': 'price' }, _createElement('span', { 'className': 'money' }, this.formatPrice(this.price * 4)), _createElement('div', { 'className': 'set-label' }, 'SET OF 4'))) : null, !(this.FitmentType === 'Wheel' || this.FitmentType === 'Tire') ? _createElement('div', {
                    'className': 'price-one-product',
                    'key': '5566'
                }, this.on_sale ? _createElement('div', {
                    'className': 'compare-at-price',
                    'key': '5697'
                }, _createElement('span', { 'className': 'money' }, this.formatPrice(this.compare_at_price))) : null, _createElement('div', { 'className': 'price' }, _createElement('span', { 'className': 'money' }, this.formatPrice(this.price)))) : null, !this.out_of_stock ? _createElement('div', {
                    'className': 'product-actions-add-to-cart-quantity',
                    'key': '5973'
                }, this.variant_ids.length > 1 ? [_createElement('a', {
                        'className': 'button button-secondary button-add-to-cart',
                        'href': this.url,
                        'aria-label': 'button',
                        'key': '60591'
                    }, '\n            Select options\n          ')] : null, this.variant_ids.length === 1 ? [
                    _createElement('quantity-input', {
                        'key': '62861',
                        'class': 'quantity-input'
                    }, _createElement('button', {
                        'onClick': onClick3.bind(this),
                        'type': 'button',
                        'className': 'quantity-input-button quantity-input-minus no-js-hidden'
                    }, '\n              \u2212\n            '), _createElement('input', {
                        'name': 'quantity',
                        'type': 'number',
                        'className': 'quantity-input-field',
                        'id': 'input-' + this.id,
                        'min': '1',
                        'defaultValue': this.FitmentType === 'Wheel' || this.FitmentType === 'Tire' ? 4 : 1
                    }), _createElement('button', {
                        'onClick': onClick4.bind(this),
                        'type': 'button',
                        'className': 'quantity-input-button quantity-input-plus no-js-hidden'
                    }, '\n              +\n            ')),
                    _createElement('form', {
                        'onClick': () => window.Convermax.handleKlaviyo(event),
                        'onSubmit': globalThis.Convermax.mountedPackagesAddToCartWithRedirect(this.id, this.variant_ids[0]),
                        'action': '/cart/add',
                        'method': 'post',
                        'id': this.id,
                        'className': 'cm_add-to-cart-form cm_add-to-cart-form-' + this.id,
                        'encType': 'multipart/form-data',
                        'key': '62863'
                    }, _createElement('input', {
                        'type': 'hidden',
                        'name': 'id',
                        'value': this.variant_ids
                    }), _createElement('input', {
                        'type': 'hidden',
                        'name': 'product_attributes',
                        'data-id': this.id,
                        'data-category': this.category,
                        'data-title': this.title,
                        'data-image': this.image,
                        'data-url': this.url,
                        'data-vendor': this.vendor,
                        'data-price': this.formatPrice(this.price),
                        'data-compare-at-price': this.formatPrice(this.compare_at_price)
                    }), _createElement('input', {
                        'className': 'cm_quantity',
                        'type': 'hidden',
                        'name': 'quantity',
                        'value': this.FitmentType === 'Wheel' || this.FitmentType === 'Tire' ? 4 : 1
                    }), _createElement('button', {
                        'className': 'button button-secondary button-add-to-cart',
                        'type': 'submit',
                        'data-form-id': this.id,
                        'name': 'add'
                    }, '\n              Add to cart\n            '))
                ] : null) : null)));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'product-grid grid grid-2-col grid-3-col-tablet grid-4-col-desktop cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []